import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

export const homeAnimation = ({ footerRef }) => {
    const tl = gsap.timeline({ delay: 0.5 });

    tl.from([".introduction", ".hero"], { x: -80, autoAlpha: 0, duration: 1.5 })
    .from("#band", { y: '-100vh', autoAlpha: 0, duration: 1.75}, '<0.5')
    .from(footerRef, { opacity: 0, duration: 1.5}, "+=0.5")
    .add(nCaptionAnimation, "+=0.5");
};

export const nCaptionAnimation = () => {
    const tl = gsap.timeline({ repeat: -1 });
    const works = ["Freelance","Developer"];

    works.forEach(work => {
        tl.add(captionAnimation({
            ref: ".caption-active", 
            text: work
        }));
    });

    return tl;
}

export const captionAnimation = ({ ref, text }) => {
    const tl = gsap.timeline({ repeat: 1, yoyo: true });
    return tl.to(ref, { text, duration: 2 });
}
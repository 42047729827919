import React, { useRef, useEffect, useState } from "react";
import serviceAnimation, { contentAnimation } from "./serviceAnimation";
import { cursorHover, cursorUnHover } from "../Cursor/cursorAnimation";
import { ReactComponent as Arrow } from "../../style/icons/arrow-right.svg";
import ParallaxOne from "../../style/images/parallax-one.jpg";
import ParallaxTwo from "../../style/images/parallax-two.jpg";
import "./services.scss";

const FrontEnd = () => {
    return (
        <>
            <h2>Front-end</h2>
            <p className="content-text">
                Fast, responsive and engaging apps 
                that bring your ideas to life.
                API Integration of your
                choice, allow you to extends the
                functionality of your apps with
                any third party. 
                Clean design patterns and thoughtful
                interactions.
            </p>
            <p className="content-page">01 <span>/ 02</span></p>
        </>
    )
}

const BackEnd = () => {
    return (
        <>
            <h2>Back-end</h2>
            <p className="content-text">
                The backend code is a behind-the-scenes part of development. 
                Implementation and configuration of systems like servers and databases,
                that allow you to create your api REST, persist your datas 
                and so much other cool stuffs.
            </p>
            <p className="content-page">02 <span>/ 02</span></p>
        </>
    )
}

function Services() {
    const serviceRef = useRef();
    const [activePage, setActivePage] = useState([
        {
            Component: () => <FrontEnd />,
            img: ParallaxTwo,
            alt: "https://unsplash.com/@pawel_czerwinski",
            active: true
        },
        {
            Component: () => <BackEnd />,
            img: ParallaxOne,
            alt: "https://unsplash.com/@joelfilip",
            active: false
        }
    ]);

    useEffect(() => {
        serviceAnimation({ serviceRef: serviceRef.current });
    }, []);

    useEffect(() => {
        contentAnimation({ full: false });
    }, [activePage])

    const handleActivePage = (nextActiveIdx) => {
        if (nextActiveIdx < 0 || (nextActiveIdx > activePage.length -1)) return null;

        setActivePage(prevPages => {
            return prevPages.map((p, idx) => ({
                ...p,
                active: idx === nextActiveIdx,
            }))
        });
        
        cursorUnHover({ cursor: ".cursor", follower: ".cursor-follower" });
    }

    return (
        <section id="services" ref={serviceRef}>
            <div className="title-container">
                <h1>Web<br />Application<br />Development</h1>
            </div>
            <div className="content-container">
                {
                    activePage.map((page, idx) => {
                        if (!page.active) return null;
                        const { Component } = page;
                        const prevIndex = idx - 1;
                        const nextIndex = idx + 1;
                        const arrowDirection = idx === activePage.length -1;
                        return (
                            <React.Fragment key={idx}>
                                <Component />
                                <div
                                    className="arrow"
                                    onMouseLeave={() => cursorUnHover({ cursor: ".cursor", follower: ".cursor-follower" })}
                                    onMouseEnter={() => cursorHover({ cursor: ".cursor", follower: ".cursor-follower" })}
                                    onClick={() => handleActivePage(arrowDirection ? prevIndex : nextIndex) }    
                                >
                                    <Arrow 
                                        style={arrowDirection ? { transform: `rotate(-180deg)` } : {}}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    })    
                }
            </div>
            <div className="parallax-container">
                { 
                    activePage.map((page, idx) => {
                        if (!page.active) return null;
                        return <img src={page.img} alt={page.alt} key={idx} />
                    }) 
                }
            </div>
        </section>
    );
}

export default Services;
import React, { useContext, useEffect } from "react";
import { NavContext } from "../NavContext";
import { cursorHover, cursorUnHover } from "../../Cursor/cursorAnimation";
import "./navbar.scss";

function NavBar() {
  const { navRef, navItems, handleActiveNavItems } = useContext(NavContext);
  const contact = navItems[navItems.length - 1];

  useEffect(() => {
    const activeIndex = navItems.findIndex(e => e.active === true);
    
    if (navItems[activeIndex].scrollAllow) {
      // Replace getElementById by Ref
      const navbar = document.getElementById("nav-container").offsetHeight;
      const element = document.getElementById(navItems[activeIndex].id);
  
      window.scrollTo({
        behavior: element ? "smooth" : "auto",
        top: element ? element.offsetTop - navbar : 0
      });
    }
  }, [navItems]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      navRef.current.style.background = navRef.current.offsetTop === 0 ? "transparent" : "white";
      navRef.current.style.boxShadow = navRef.current.offsetTop === 0 ? "none" : "0px 0px 1px rgba(0, 0, 0, 0.5)";
    });

    return window.removeEventListener('scroll', () => {});
  }, [navRef]);

  return (
    <header className="nav-container" id="nav-container" ref={navRef}>
      <nav>
        <ul>
          {navItems.map((navItem, idx) => {
            if (idx === navItems.length - 1) return null;
            return (
              <li 
                key={navItem.id}
                className={ navItem.active ? "selected" : null}
                onClick={() => handleActiveNavItems(navItem.id, true) }
                onMouseEnter={() => cursorHover({ cursor: ".cursor", follower: ".cursor-follower" })}
                onMouseLeave={() => cursorUnHover({ cursor: ".cursor", follower: ".cursor-follower" })}
              >
                  {navItem.name}
              </li>
            );
          })}
        </ul>
        <button
           className={contact.active ? "active" : "contact"}
           onClick={() => handleActiveNavItems(contact.id, true) }
           onMouseEnter={() => cursorHover({ cursor: ".cursor", follower: ".cursor-follower" })}
           onMouseLeave={() => cursorUnHover({ cursor: ".cursor", follower: ".cursor-follower" })}
        >
          {contact.name}
        </button>
      </nav>
    </header>
  );
}

export default NavBar;

import React, { useRef, useEffect } from "react";
import { cursorHover, cursorUnHover } from "../Cursor/cursorAnimation";
import { aboutAnimation } from "./aboutAnimation";
import { ReactComponent as Nrandia } from "../../style/icons/nrandria.svg"
import "./aboutme.scss";

const calculateAge = birthday => {
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

const AboutLink = (props) => {
    const {link, text} = props;

    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => cursorHover({ cursor: ".cursor", follower: ".cursor-follower" })}
            onMouseLeave={() => cursorUnHover({ cursor: ".cursor", follower: ".cursor-follower" })}
        >
            {text}
        </a>
    );
}

function AboutMe() {
    const aboutRef = useRef();

    useEffect(() => {
        aboutAnimation({ aboutRef: aboutRef.current });
    }, []);


    return (
        <section id="about-me" ref={aboutRef}>
                <div className="rectangle">
                    <h2 className="title">About me</h2>
                    <p>Nicolas is a {calculateAge(new Date('07 Nov 1995'))} years old developer from Paris, France, who discovered the whole world of programming at <AboutLink link={"https://www.42.fr/"} text={"school 42"}/>.</p>
                    <p>He coded many projects in different technologies, from C to Javascript in which he specialized in order to become freelancer and combine code and diversity of projects.</p>
                    <p>Besides that, he loves tricking, street workout and breakdance.</p>
                    <p>Many of these artistic sports also led him to travels, where he took a lot of pleasure to film and edit his <AboutLink link="https://www.youtube.com/channel/UCfm2f2eS39_q0lGAgPo1Yqw/videos" text="Youtube videos"/>.</p>
                    <Nrandia className="label"/>
                </div>
                <p className="simple">Coder, filmmaker, meme lover and tricking athlete with a thirst for learning.</p>
        </section>
    )
}

export default AboutMe;
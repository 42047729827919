import React from "react";
import Cursor from "./components/Cursor/Cursor";
import Main from "./components/Main";
import "./style/index.scss";


function App() {
  
    return (
      <Cursor>
        <Main />
      </Cursor>
  );
}

export default App;

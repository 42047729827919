import React from "react";
import { ReactComponent as YouTube } from "../../style/icons/youtube.svg";
import { ReactComponent as LinkedIn } from "../../style/icons/linkedin.svg";
import { ReactComponent as GitHub } from "../../style/icons/github.svg";
import { cursorHover, cursorUnHover } from "../Cursor/cursorAnimation";
import "./footer.scss";

const Footer = React.forwardRef((props, ref) => {
    const year = new Date().getFullYear();
    const socials = [
        {
            name: "YouTube",
            href: "https://www.youtube.com/channel/UCfm2f2eS39_q0lGAgPo1Yqw",
            svg: <YouTube className={"icon"}/>
        },
        {
            name: "LinkedIn",
            href: "https://linkedin.com/in/nrandr1a",
            svg: <LinkedIn className={"icon"}/>
        },
        {
            name: "GitHub",
            href: "https://github.com/BABAK0T0",
            svg: <GitHub className={"icon"}/>
        },
    ];

    return (
        <footer ref={ref}>
            <div className="social-container">
                {
                    socials.map((social) => {
                        return (
                            <a 
                                key={social.name}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={social.href}
                                onMouseEnter={() => cursorHover({ cursor: ".cursor", follower: ".cursor-follower" })}
                                onMouseLeave={() => cursorUnHover({ cursor: ".cursor", follower: ".cursor-follower" })}
                            >
                                {social.svg}
                            </a>
                        );
                    })
                }
                <div className="dash"></div>
            </div>
            <h5 className="copyright"><p>© {year} NRANDRIA</p> ALL RIGHTS RESERVED</h5>
        </footer>
    );
});

export default Footer;
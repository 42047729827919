import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { NavProvider } from "./Navigation/NavContext"
import NavBar from "./Navigation/NavBar/NavBar";
import Home from "./Home/Home";
import Services from "./Services/Services";
import AboutMe from "./About/AboutMe";
import Contact from "./Contact/Contact";

function Main() {
    const [navItems, setNavItems] = useState([
        {
            id:"home",
            name: "home",
            hash: "#home",
            active: true,
            scrollAllow: true,
        },
        {
            id: "services",
            name: "services",
            hash: "#services",
            active: false,
            scrollAllow: false,
        },
        {
            id:"about-me",
            name: "about me",
            hash: "#about-me",
            active: false,
            scrollAllow: false,
        },
        {
            id:"contact",
            name: "hire me",
            hash: "#contact",
            active: false,
            scrollAllow: false,
        }
    ]);

    const handleActiveNavItems = (id, scrollAllow) => {
        setNavItems(prevNavItems => {
            return prevNavItems.map(n => ({
                ...n,
                active: n.id === id,
                scrollAllow: n.id === id ? scrollAllow : n.scrollAllow,
            }))
        });
    }

    const sections = [
        {
            id: "home",
            component: () => <Home />,
        },
        {
            id: "services",
            component: () => <Services />
        },
        {
            id: "about-me",
            component: () => <AboutMe />,
        },
        {
            id: "contact",
            component: () => <Contact />,
        }
    ];

    return (
        <NavProvider value={{ navItems, handleActiveNavItems }}>
            <NavBar />
            {
                sections.map((s, idx) => {
                    return (
                        <VisibilitySensor
                            key={idx}
                            scrollCheck
                            scrollDelay={500}
                            onChange={(isVisible) => isVisible && handleActiveNavItems(s.id, false)}
                        >
                            {s.component}
                        </VisibilitySensor>
                    );
                })
            }
      </NavProvider>
    );
    
}

export default Main;
import React, { createContext, useRef } from "react";

export const NavContext = createContext();

export const NavProvider = props => {

  const data = {
    navRef: useRef(),
    navItems: props.value.navItems,
    handleActiveNavItems: props.value.handleActiveNavItems,
  }

  return <NavContext.Provider value={data}>{props.children}</NavContext.Provider>;
}
import React, { useEffect, useRef, useState } from "react";
import cursorAnimation from "./cursorAnimation";
import "./cursor.scss";

const Cursor = (props) => {

    const cursorRef = useRef();
    const followerRef = useRef();

    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);

    useEffect(() => {
        cursorAnimation(
            { cursor: cursorRef.current, follower: followerRef.current },
            { mouseX, mouseY }
        );
    }, [mouseX, mouseY]);

    return (
        <div 
            onMouseMove={(e) => {
                setMouseX(e.clientX);
                setMouseY(e.clientY);
            }}
        >
            <div className="cursor" ref={cursorRef}></div>
            <div className="cursor-follower" ref={followerRef}></div>
            {props.children}
        </div>
    );
}

export default Cursor;
import React, { useRef, useEffect } from "react";
import Footer from "../Footer/Footer";
import { homeAnimation } from "./homeAnimation";
import Hero from "../../../src/style/images/nrandria.png";
import "./home.scss";

function Home() {
  const homeRef = useRef();
  const footerRef = useRef()

  useEffect(() => {
    homeAnimation({ footerRef: footerRef.current });
  }, []);

  return (
    <section id="home" ref={homeRef}>
      <div className="introduction">
        <h1>
          <div className="caption">
            Hello,
            <br />
            I'm Nicolas
          </div>
          <span className="caption-active"></span>
        </h1>
      </div>
      <div id="band"></div>
      <div className="hero">
        <img src={Hero} alt="nrandria" />
      </div>
      <Footer ref={footerRef} />
    </section>
  );
}

export default Home;
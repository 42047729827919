import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const serviceAnimation = ({ serviceRef }) => {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: serviceRef,
            start: "top center",
            toggleActions: 'play none play none'
        },
        duration: 1,
    });

    tl.from(".title-container", { xPercent: -100, autoAlpha: 0, duration: 1.2 })
    .from(".title-container h1", { y: -40, autoAlpha: 0 })
    .add(contentAnimation({}));
}

export const contentAnimation = ({ full = true }) => {
    const tl = gsap.timeline();

    if (full) tl.from(".content-container", { xPercent: -150, duration: 1.2 });
    tl.from(".content-container h2", { x: -40, autoAlpha: 0 })
    .from(".content-text", { x: -60, autoAlpha: 0, duration: 0.5 })
    .from(".content-page", { x: -20, autoAlpha: 0})
    .from([".arrow", ".content-page span"], { x: 20, autoAlpha: 0 })
    if (full) tl.from(".parallax-container", { xPercent: -250, duration: 1 });
    tl.from(".parallax-container img", { scale: 1.5, autoAlpha: 0, duration: 0.5 })

    return tl;
}

export default serviceAnimation;
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const contactAnimation = ({ contactRef, footerRef }) => {

    const tl = gsap.timeline({
        defaults: {
            ease: "none",
        },
        scrollTrigger:{
            trigger: contactRef,
            start: 'top center',
            end: 'bottom bottom',
            scrub: true,
        } 
    });


    tl.from(".first", { y: -60, autoAlpha: 0 })
    .from(".second", { y: -40, autoAlpha: 0 })
    .from(".third", { y: -20, autoAlpha: 0 })
    .from(".qrcode", { y: 40, autoAlpha: 0, scale: 0.3 })
    .from(footerRef, { autoAlpha: 0 });
}
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger);

export const aboutAnimation = ({ aboutRef }) => {
    const tl = gsap.timeline({
        scrollTrigger: {
            trigger: aboutRef,
            start: "top center",
            toggleActions: 'play none play none',
        },
        duration: 1,
    });

    tl.from("section .rectangle", { xPercent: -100, autoAlpha: 0, duration: 1.2 })
    .from(".rectangle .title", { y: -40, autoAlpha: 0 })
    .from(".rectangle .label", {yPercent: -125, autoAlpha: 0, duration: 1.2})
    .from(".rectangle p", { x: 40, autoAlpha: 0, stagger: 0.2 })
    .from("section .simple", { autoAlpha: 0, duration: 1 });
}
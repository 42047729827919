import React, { useRef, useEffect } from "react";
import Footer from "../Footer/Footer";
import { contactAnimation } from "./contactAnimation";
import { cursorHover, cursorUnHover } from "../Cursor/cursorAnimation";
import { ReactComponent as MailSVG } from "../../style/images/mail.svg"
import "./contact.scss";

function Contact() {
    const contactRef = useRef();
    const footerRef = useRef();

    useEffect(() => {
        contactAnimation({
            contactRef: contactRef.current,
            footerRef: footerRef.current,
        });
    }, []);

    return (
        <section id="contact" ref={contactRef}>
            <div className="title">
                <p className="first">I collaborate with <span>ambitious</span></p>
                <p className="second">brands and <span>people</span>.</p>
                <p className="third">Are you with me ?</p>
            </div>
            <div className="qrcode">
                <h1>
                    <a 
                        href="mailto:hello@nrandria.com"
                        onMouseEnter={() => cursorHover({ cursor: ".cursor", follower: ".cursor-follower" })}
                        onMouseLeave={() => cursorUnHover({ cursor: ".cursor", follower: ".cursor-follower" })}
                    >
                        <MailSVG />
                        Drop me an email
                    </a>
                </h1>
            </div>
            <Footer ref={footerRef} scrollTrigger/>
        </section>
    );
}

export default Contact;
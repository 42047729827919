import { gsap } from "gsap";

const cursorAnimation = (refs, mouse) => {
    const { cursor, follower } = refs 
    const { mouseX, mouseY } = mouse; 
    gsap.to(cursor, 0.3, { x: mouseX, y: mouseY }, 0.3);
    gsap.to(follower, 0.7, { x: mouseX, y: mouseY }, 0.7);  
}

export const cursorHover = ({ cursor, follower }) => {
    gsap.to(cursor, {
        opacity: 1,
        scale: 0
    });

    gsap.to(follower, {
        scale: 3
    });  
}

export const cursorUnHover = ({ cursor, follower }) => {
    gsap.to(cursor, {
        opacity: 1,
        scale: 1
    });

    gsap.to(follower, {
        scale: 1
    }); 
}

export default cursorAnimation;